<template>
  <div class="c_lp_text_icon_list">
    <LPText
      class="c_lp_text_icon_list__content"
      :subheader="header"
      :eyebrow="eyebrow"
      :text="text"
      text-alignment="center"
      :columns="columns"
    ></LPText>
    <Grid
      :columns="['md', 'lg'].includes($mq) ? icons.length : 3"
      :gap="['sm', 'md'].includes($mq) ? '40px' : '60px'"
      class="c_lp_text_icon_list__list"
    >
      <LinkField
        class="c_lp_text_icon_list__list__icon__wrapper c_text--center"
        :class="{ 'c_lp_text_icon_list__list__icon__wrapper--button': parseLinkField(icon, 'superTable').valid }"
        :is="parseLinkField(icon, 'superTable').valid ? 'LinkField' : 'div'"
        :super-table-field="parseLinkField(icon, 'superTable').valid ? icon : null"
        v-for="(icon, index) in icons"
        :key="index"
      >
        <img class="c_lp_text_icon_list__list__icon" :src="icon.icon[0].url" :alt="icon.icon[0].title" />
        <p
          class="c_lp_text_icon_list__list__icon__label c_text--graphik_semibold"
          v-if="icon.label"
          v-html="icon.label"
        >
          {{ icon.label }}
        </p>
      </LinkField>
    </Grid>
  </div>
</template>

<script>
import { parseLinkField, log } from 'JS/global.js';
import LinkField from 'Components/link_field/LinkField.vue';
import Grid from 'Components/grid/Grid.vue';
import LPText from 'Components/lp_text/LPText.vue';

export default {
  components: {
    Grid,
    LinkField,
    LPText,
  },
  data() {
    return {};
  },
  props: {
    columns: Number,
    eyebrow: String,
    header: String,
    icons: Array,
    text: String,
    textAlignment: String,
  },
  methods: {
    parseLinkField: parseLinkField,
  },
};
</script>

<style lang="scss">
.c_lp_text_icon_list {
  $self: &;
  @at-root #{$self}__list {
    padding: px_to_rem(50) px_to_rem(20) 0;
    @include mq($mq_sm_to_md) {
      padding: px_to_rem(50) px_to_rem(40) 0;
    }

    @at-root #{&}__icon {
      max-width: px_to_rem(45);
      max-height: px_to_rem(45);
      margin: 0 auto;
      @at-root #{&}__wrapper {
        @at-root #{&}--button {
          text-decoration: none;
          color: $color_black;
          &:hover,
          &:focus {
            color: $color_yellow;
          }
          .c_box--black & {
            color: $color_white;
            &:hover,
            &:focus {
              color: $color_yellow;
            }
          }
          .c_box--yellow & {
            &:hover,
            &:focus {
              color: $color_white;
            }
          }
        }
      }
      @at-root #{&}__label {
        font-size: px_to_rem(13);
      }
    }
  }
}
</style>
