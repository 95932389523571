<template>
  <div class="c_lp_text" :class="textClasses">
    <p class="c_lp_text__eyebrow respect-breaks" v-html="eyebrow" v-if="eyebrow"></p>
    <h2
      class="c_lp_text__subheader c_header--10 c_text--graphik_bold respect-breaks"
      v-html="subheader"
      v-if="subheader"
      :id="slug(subheader)"
    >
      {{ subheader }}
    </h2>
    <div
      class="c_lp_text__text c_text"
      :class="columns != 1 ? ['c_lp_text__text--columns', 'c_lp_text__text--columns--' + columns, 'c_text--left'] : ''"
      v-html="text"
      v-if="text"
    />
    <div
      class="c_lp_text__buttons c_buttons cta_container"
      :class="[`c_buttons--${textAlignment}`]"
      v-if="buttonLinkField ? Object.keys(buttonLinkField).length : false"
    >
      <LinkField
        :button-attrs="{ color: buttonOutline ? 'white' : 'red', outline: buttonOutline, styling: primaryCtaBtnTheme }"
        :super-table-field="buttonLinkField"
      />
      <LinkField
        :button-attrs="{ color: buttonOutline ? 'white' : 'red', outline: buttonOutline, styling: primaryCtaBtnTheme }"
        :super-table-field="buttonTwoLinkField"
        v-if="buttonTwoLinkField"
      />
      <span v-if="primaryCtaBtnTheme == 'default' || primaryCtaBtnTheme == 'default2023'">
        <a
          v-if="primaryCtaBtn && primaryCtaBtnLabel"
          :class="{
            'c_button primary-btn': primaryCtaBtnTheme === 'default',
            'c_button_2022 c_button_2022__primary': primaryCtaBtnTheme === 'default2023',
          }"
          :href="primaryCtaBtn"
          :aria-label="primaryCtaBtnAriaLabel"
          :target="primaryCtaBtnOpenInANewWindow"
          >{{ primaryCtaBtnLabel }}</a
        >
      </span>
      <!--<span v-if="secondaryCtaBtnTheme == 'default'">
        <a
          v-if="secondaryCtaBtn && secondaryCtaBtnLabel"
          :class="['c_button', 'secondary-btn', secondaryCtaBtnTheme]"
          :href="secondaryCtaBtn"
          :aria-label="secondaryCtaBtnAriaLabel"
          :target="secondaryCtaBtnOpenInANewWindow"
          >{{ secondaryCtaBtnLabel }}</a
        >
      </span>-->
    </div>
  </div>
</template>

<script>
import LinkField from 'Components/link_field/LinkField.vue';
import { slugify } from 'JS/global.js';

export default {
  components: {
    LinkField,
  },
  data() {
    return {};
  },
  props: {
    buttonLinkField: Object,
    buttonTwoLinkField: Object,
    buttonOutline: { type: Boolean, default: false },
    columns: {
      type: Number,
      default: 1,
    },
    eyebrow: String,
    eyebrowStyle: { type: String, default: 'semibold' }, // regular
    subheader: String,
    text: String,
    textAlignment: { type: String, default: 'left' }, // left, center, right
    primaryCtaBtn: String,
    primaryCtaBtnLabel: String,
    primaryCtaBtnAriaLabel: String,
    primaryCtaBtnTheme: { type: String, default: 'default' },
    primaryCtaBtnOpenInANewWindow: Boolean,
    /* secondaryCtaBtn: String,
    secondaryCtaBtnLabel: String,
    secondaryCtaBtnAriaLabel: String,
    secondaryCtaBtnTheme: { type: String, default: 'default' },
    secondaryCtaBtnOpenInANewWindow: Boolean, */
  },
  computed: {
    textClasses() {
      const classes = [`c_text--${this.textAlignment}`, `c_lp_text--${this.textAlignment}`];
      // Add classes based on block type and style fields
      if (this.eyebrowStyle === 'regular') {
        classes.push('c_lp_text__eyebrow--regular');
      }
      return classes;
    },
  },
  methods: {
    init() {
      this.setButtons();
    },
    setButtons() {
      const buttonArray = [];
      // for (const i in this.buttons) {
      const content = {};
      const primaryBtn = {};
      primaryBtn.type = 'default';
      // const ctaBtnStringArray = this.text[i].primaryCtaBtn.split(':');
      primaryBtn.url = this.primaryCtaBtn;
      primaryBtn.label = this.primaryCtaBtnLabel;
      primaryBtn.themeClass = this.getCtaButtonClass(this.primaryCtaBtnTheme);
      primaryBtn.ariaLabel = this.primaryCtaBtnAriaLabel;
      primaryBtn.target = '_self';
      if (this.primaryCtaBtnOpenInANewWindow) {
        primaryBtn.target = '_blank';
      }
      content.primaryBtn = primaryBtn;
      buttonArray.push(primaryBtn);

      /* const secondaryBtn = {};
      secondaryBtn.type = 'default';
      // const secondCtaBtnStringArray = this.text[i].secondaryCtaBtn.split(':');
      secondaryBtn.url = this.secondaryCtaBtn;
      secondaryBtn.label = this.secondaryCtaBtnLabel;
      secondaryBtn.themeClass = this.getCtaButtonClass(this.secondaryCtaBtnTheme);
      secondaryBtn.ariaLabel = this.secondaryCtaBtnAriaLabel;
      secondaryBtn.target = '_self';
      if (this.secondaryCtaBtnOpenInANewWindow) {
        secondaryBtn.target = '_blank';
      }
      content.secondaryBtn = secondaryBtn;
      buttonArray.push(secondaryBtn); */
      // }
      // this.buttonData = buttonArray;
    },
    getCtaButtonClass(btnTYpe) {
      let btnClass = 'defaultTheme';
      switch (btnTYpe) {
        case 'slideTheme':
          btnClass = 'slideTheme';
          break;
        default:
      }
      return btnClass;
    },
    slug(subheader) {
      let slug = slugify(subheader);
      if (slug.match(/[a-z]/i)) {
        if (slug === 'united-kingdom') {
          slug = 'UK';
        } else if (slug === 'united-arab-emirates') {
          slug = 'UAE';
        } else if (slug.includes('-')) {
          const slugarr = slug.split('-');
          const slug2 = slugarr[1][0].toUpperCase() + slugarr[1].substring(1);
          slug = slugarr[0] + slug2;
          slug = slug[0].toUpperCase() + slug.substring(1);
          // slug = slugarr[0] + slugarr[1];
        } else {
          slug = slug[0].toUpperCase() + slug.substring(1);
        }
      } else {
        if (subheader.includes(' ')) {
          const slugarr = subheader.split(' ');
          slug = slugarr[0] + slugarr[1];
        }
        const slug2 = encodeURI(subheader);
        // slug2 = decodeURI(slug2);
        slug = slug2;
      }
      return slug;
    },
  },
  created() {},
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
.c_lp_text {
  $self: &;
  max-width: var(--lp-text-max-width, 850px);
  display: flex;
  flex-direction: column;

ul ul {
  list-style-type: disc; 
  text-indent: -10px;
  padding-left: -20px; 
}

ul ul li {
  position: relative;
  padding-left: 10px;
  margin-top: 10px;
}

ul li ul::before {
  list-style-type: none; 
  content: ""; 
  position: absolute;
  left: -20px; 
}

ul li ul li {
  list-style-type: none; 
  content: ""; 
  position: relative;
  padding-left: 5px; 

  &::before {
    content: "-"; 
    position: absolute;
    left: -10px;
    top: 0;
  }
}

  @at-root #{$self}--left {
    margin-right: auto;
    align-items: flex-start;
  }
  @at-root #{$self}--center {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  @at-root #{$self}--right {
    margin-left: auto;
    align-items: flex-end;
  }
  @at-root #{$self}__eyebrow {
    @include font('graphik_regular');
    font-size: 1.1rem;
    &:not(:last-child) {
      margin-bottom: var(--eyebrow-mb, 1.2rem);
    }
    @include mq($mq_sm_to_md) {
      @include font('graphik_semibold');
      font-size: var(--eyebrow-fz, 1rem);
      @at-root #{&}--regular & {
        @include font('graphik_light');
        opacity: 0.6;
      }
    }
  }
  @at-root #{$self}__subheader {
    &:not(:last-child) {
      margin-bottom: var(--subheader-mb, 0.85rem);
    }
  }
  @at-root #{$self}__text {
    --text-fz: var(--text-size, $lp_text_default_font_size);
    width: 100%;
    @at-root #{&}--columns {
      columns: 1;
      column-gap: px_to_rem(40);
      @at-root #{&}--2 {
        @include mq($mq_sm_to_md) {
          columns: 2;
        }
      }
      @at-root #{&}--3 {
        columns: 3;
      }
    }
    &:not(:last-child) {
      margin-bottom: var(--text-mb, 1.5rem);
    }
  }
  .cta_container {
    display: flex;
    flex-direction: row;
    justify-content: var(--desktop-justify-align);
    button,
    a {
      margin-bottom: 0px;
      width: auto;
      border-width: 2px;
      @include transition(all 0.25s ease);
    }
    a:last-child {
      margin-right: 0px;
    }
    .primary-btn {
      background-color: var(--color-red);
      color: #fff;
      border: none;
    }
    .primary-btn:hover {
      background-color: #000;
      color: #fff;
      cursor: pointer;
    }
    /* .secondary-btn {
      background-color: transparent;
      color: #000;
      border: #000000 2px solid;
    }
    .secondary-btn:hover {
      background-color: #000000;
      color: #ffffff;
      border-color: #000000;
      cursor: pointer;
    } */
    a.defaultTheme {
      background-color: var(--color-red);
      color: #fff;
      border: none;
    }
    a.defaultTheme:hover {
      background-color: #000;
      color: #fff;
    }
  }
}
.respect-breaks {
  white-space: pre-line;
  * {
    white-space: pre-line;
  }
}
</style>
